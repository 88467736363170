import md5 from "md5";
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage'

export class Tormusik{
    constructor(
        id,
        name,
        turnier_id
    ) {
        this.id = id || md5(Date.now())
        this.name = name || ''
        this.turnier_id = turnier_id
        this.musikRef = this.name ? ref(getStorage(), 'Turniere/'+this.turnier_id+'/'+this.name) : ''
        this.uploadProgress = {
            state: '',
            progress: 0,
            errorcode: ''
        }
        this.audioState = {
            state: 'stopped',
        }
    }

    uploadMusik(file) {
        if(!(file instanceof File)){
            return
        }
        this.uploadProgress = {
            state: '',
            progress: 0,
            errorcode: ''
        }
        this.name = file.name
        this.musikRef = ref(getStorage(), 'Turniere/'+this.turnier_id+'/'+this.name)
        const uploadTask = uploadBytesResumable(this.musikRef, file)
        uploadTask.on('state_changed', 
            (snap) => {
                this.uploadProgress.state = snap.state
                this.uploadProgress.progress = (snap.bytesTransferred / snap.totalBytes) * 100
            },
            (error) => {
                this.uploadProgress.errorcode = error.code
            }
        )
    }

    getUrl(){
        if(this.musikRef) {
            return getDownloadURL(this.musikRef).then(url => {
                return url
            })
        }
        else {
            return ''
        }
    }

    init(){
        if(this.musikRef) {
            getDownloadURL(this.musikRef).then(url => {
                this.audio = new Audio(url)
            })
        }
        else {
            return ''
        }
    }

    play() {
        if(this.audio instanceof Audio){
            if(this.audio.readyState >= 4){
                this.audioState.state = 'play'
                this.audio.play();
                this.audio.onended = () => {
                    this.stop()
                }
                return
            }
        } else {
            this.init()
        }

        setTimeout(() => {
            this.play()
        }, 100);
    }

    pause(){
        this.audioState.state = 'paused'
        this.audio.pause()
    }
    
    stop(){
        this.audioState.state = 'stopped'
        this.audio.pause()
        this.audio.currentTime = 0
    }

    reset() {
        this.audio.currentTime = 0
    }

    toFirestore(){
        return {
            id: this.id,
            name: this.name,
        }
    }

}