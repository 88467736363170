import {
  getFirestore,
  doc,
  onSnapshot,
  collection,
  where,
  query,
} from '@firebase/firestore'
import rundeConverter from '../classes/rundeConverter'
import teamConverter from '../classes/spiel/teamConverter'
import Turnier from '../classes/turnier/turnier'
import turnierConverter from '@/classes/turnier/turnierConverter'

const db = getFirestore()

const TurnierModule = {
  state: {
    turnier: new Turnier(),
    turnierunsubsribe: () => {},
    aktuelles_spiel: '',
    client: {
      id: '',
      turnier: '',
      snap: '',
      turnierSnap: '',
    },
  },
  getters: {
    turnier(state) {
      return state.turnier
    },
    aktuelles_spiel(state) {
      return state.aktuelles_spiel
    },
    client(state) {
      return state.client
    },
  },
  mutations: {
    SET_CLIENT_ID(state, client_id) {
      state.client.id = client_id
      const clientRef = doc(getFirestore(), 'Client/'+client_id)
      state.client.snap = onSnapshot(clientRef, snap => {
        if(snap.exists()){
          const daten = snap.data()
          state.client.turnier = daten.turnier
          state.client.type = daten.type

          if(daten.turnier){
            const turnierRef = doc(getFirestore(), 'Turniere/'+daten.turnier).withConverter(turnierConverter)
            state.client.turnierSnap = onSnapshot(turnierRef, turnier => {
              state.turnier = turnier.data()
            })
          }
        }
      })
    },
  },
  actions: {
    setClient({ commit }, params) {
      commit('SET_CLIENT_ID', params)
    },
    closeTurnier({ commit }) {
      commit('UNSET_TURNIER')
    },
    openTurnier({ commit }, data) {
      commit('SET_TURNIER', data)
    },
  },
}

export default TurnierModule
