<template>
  <v-app style="width: 100vw; height: 100vh; overflow: hidden;">
    <v-container fluid class="pa-0" style="width: 100vw; height: 100vh; overflow: hidden;">
      <v-img src="@/assets/background/bg.jpg" contain></v-img>
    </v-container>
    <v-main style="background: rgba(0,0,0,0.7); height: 100vh; width: 100vw; overflow: hidden; position: fixed; top: 0;">
      <v-container fluid style="height: 100%;">
        <v-row justify="center" style="height: 100%;">
          <v-col cols="8" align-self="center">
            <v-img src="@/assets/logos/iturnier-logo_w.png" width="40%" class="mx-auto mb-5" contain></v-img>
            <v-row justify="center" style="margin-top: 80px;">
              <v-col cols="6">
                <v-card class="rounded-xl pa-4" color="rgba(50,50,50,0.5)" dark>
                  <v-row justify="space-around">
                    <v-col cols="2" align-self="center">
                      <font-awesome-icon icon="fa-light fa-display" size="5x" />
                    </v-col>
                    <v-col cols="8" class="text-center">
                      <h1 class="vox-round-regular">Verbinde dein Turnier</h1>
                      <h1 class="display-4 vox-bold">{{ client.id }}</h1>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'HomeView',
  computed: {
    ...mapGetters({
      client: 'client'
    })
  }
}
</script>

<style>
  .v-application .display-4 {
    font-family: "vox-round", sans-serif !important;
    font-weight: 700;
    font-style: normal;
  }
</style>
