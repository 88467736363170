<template>
  <v-app>
    <home-view v-if="!client.turnier"></home-view>
    <anzeigetafel v-if="turnier && turnier.name && client.type == 'Anzeigetafel'"></anzeigetafel>
    <turnierdisplay v-if="turnier && turnier.name && client.type == 'Turnierdisplay'"></turnierdisplay>
    <cafeteriadisplay v-if="turnier && turnier.name && client.type == 'Cafeteria'"></cafeteriadisplay>
  </v-app>
</template>

<script>
import { doc, getDoc, getFirestore, setDoc } from '@firebase/firestore';
import { mapGetters } from 'vuex';
import HomeView from './views/HomeView.vue';
import Anzeigetafel from './components/anzeigetafel/index.vue'
import Turnierdisplay from './components/turnierdisplay/index.vue'
import Cafeteriadisplay from './components/cafeteriadisplay/index.vue'

export default {
  components: { HomeView, Anzeigetafel, Turnierdisplay, Cafeteriadisplay },
   name: 'App',
   computed: {
    ...mapGetters({
      client: 'client',
      turnier: 'turnier'
    })
   },
   mounted(){
    this.init()
    console.log(this.getCookie('iturnierid'))
   },
   methods: {
    getClientId(){
      const alphabet = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
      const min = 0
      const max = alphabet.length
      var id = ''
      for(var i=0; i < 6; i++){
        id += alphabet[Math.floor(Math.random() * (max - min + 1)) + min];
      }
      return id
    },
    async setClient(){
      var id = this.getClientId()
      const clientRef = doc(getFirestore(), 'Client/'+id)
      const clientDoc = await getDoc(clientRef)
      if(clientDoc.exists()){
        this.getClient()
      }
      else {
        await setDoc(clientRef, {
          id: id,
          turnier: ''
        })
        this.$store.dispatch('setClient', id)
        this.setCookie("iturnierid", id, 365);
      }
    },
    setCookie(cname, cvalue, exdays) {
      const d = new Date();
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
      let expires = "expires="+d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
    getCookie(cname) {
      let name = cname + "=";
      let ca = document.cookie.split(';');
      for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    init() {
      let id = this.getCookie("iturnierid");
      if (!id) {
        this.setClient()
      }
      else {
        this.$store.dispatch('setClient', id)
      }
    }
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: "Roboto";
  font-weight: 100;
  font-style: normal;
  src: local("Roboto"), url("@/assets/fonts/Roboto/Roboto-Thin.ttf") format("ttf"), url("@/assets/fonts/Roboto/Roboto-Thin.ttf") format("ttf");
}

@font-face {
  font-family: "Roboto";
  font-weight: 300;
  font-style: normal;
  src: local("Roboto"), url("@/assets/fonts/Roboto/Roboto-Light.ttf") format("ttf"), url("@/assets/fonts/Roboto/Roboto-Light.ttf") format("ttf");
}

@font-face {
  font-family: "Roboto";
  font-weight: 400;
  font-style: normal;
  font-display: auto;
  unicode-range: U+000-5FF;
  src: local("Roboto"), url("@/assets/fonts/Roboto/Roboto-Regular.ttf") format("ttf"), url("@/assets/fonts/Roboto/Roboto-Regular.ttf") format("ttf");
}

@font-face {
  font-family: "Roboto";
  font-weight: 500;
  font-style: normal;
  src: local("Roboto"), url("@/assets/fonts/Roboto/Roboto-Medium.ttf") format("ttf"), url("@/assets/fonts/Roboto/Roboto-Medium.ttf") format("ttf");
}

@font-face {
  font-family: "Roboto";
  font-weight: 700;
  font-style: bold;
  src: local("Roboto"), url("@/assets/fonts/Roboto/Roboto-Bold.ttf") format("ttf"), url("@/assets/fonts/Roboto/Roboto-Bold.ttf") format("ttf");
}

@font-face {
  font-family: "Roboto";
  font-weight: 900;
  font-style: bold;
  src: local("Roboto"), url("@/assets/fonts/Roboto/Roboto-Black.ttf") format("ttf"), url("@/assets/fonts/Roboto/Roboto-Black.ttf") format("ttf");
}

/* Regular */
  @font-face {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    src: local('Segoe UI'), local('Segoe UI'), url('./assets/font/SegoeUI.woff2') format('woff2');
  }
  /* Semibold */
  @font-face {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    src: local('Segoe UI'), local('Segoe UI'), url('./assets/font/SegoeUI-Semibold.woff2') format('woff2');
  }
  /* Bold */
  @font-face {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 800;
    src: local('Segoe UI'), local('Segoe UI'), url('./assets/font/SegoeUI-Bold.woff2') format('woff2');
  }

body, .v-application {
  font-family: "Segoe UI" !important;
}

*::-webkit-scrollbar {
  display: none;
}

.vox-italic {
    font-family: "vox", sans-serif;
    font-weight: 400;
    font-style: italic;
}

.vox-regular {
    font-family: "vox", sans-serif;
    font-weight: 400;
    font-style: italic;
}

.vox-bold {
    font-family: "vox", sans-serif;
    font-weight: 700;
    font-style: normal;
}

.vox-bold-italic {
    font-family: "vox", sans-serif;
    font-weight: 700;
    font-style: italic;
}

.vox-round-regular {
    font-family: "vox-round", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.vox-round-italic {
    font-family: "vox-round", sans-serif;
    font-weight: 400;
    font-style: italic;
}

.vox-round-bold {
    font-family: "vox-round", sans-serif;
    font-weight: 700;
    font-style: normal;
}

.vox-round-bold-italic {
    font-family: "vox-round", sans-serif;
    font-weight: 700;
    font-style: italic;
}
</style>
