import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fass } from '@fortawesome/sharp-solid-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fat } from '@fortawesome/pro-thin-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';

library.add(fab, far, fas, fass, fal, fat, fad);

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(Vuetify);

export default new Vuetify({
});
