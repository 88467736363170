<template>
     <v-app dark>
        <v-container fluid class="pa-0" style="position: fixed; height: 100vh; width: 100vw; overflow: hidden;" :style="turnier && turnier.name ? turnier.anzeigetafel_settings.css : ''">
            <v-img
                v-if="turnier && turnier.anzeigetafel_settings.background_image.path"
                width="100vw"
                height="100vh"
                src="@/assets/backgrounds/background-arag-cup.jpg"
                position="center center"
                gradient="to top right, rgba(0,0,0,.2), rgba(0,0,0,.6)"
            />
            <v-row justify="center" class="py-3" style="position: absolute; bottom: 0; width: 100vw;">
                <v-col cols="auto">
                    <v-img :src="turnier.website.logo.path" width="400"></v-img>
                </v-col>
            </v-row>
            <v-row justify="end" class="py-3" style="position: absolute; bottom: 0; width: 100vw;">
                <v-col cols="auto">
                    <v-card class="rounded-xl pa-4 text-center">
                        <h2 class="vox-round-bold">ONLINE SPIELPLAN</h2>
                        <qrcode-vue :value="`https://iturnier.de/${turnier.alias}`" level="H" size="200"></qrcode-vue>
                        <small>https://iturnier.de/{{turnier.alias}}</small>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-window>
            <v-window-item>
                <v-row justify="center" style="height: 90vh; overflow: hidden;">
                    <v-col cols="8" class="pa-5" align-self="center">
                        <v-window v-model="spielplan_seite" class="pa-5">
                            <v-window-item>
                                <v-row justify="center" align="center">
                                    <v-col cols="12" class="text-center">
                                        <h1 class="display-2 vox-round-bold-italic">
                                            - EINFACH KONTAKTLOS ZAHLEN -
                                        </h1>
                                    </v-col>
                                    <v-col cols="auto" class="mx-2">
                                        <font-awesome-icon icon="fa-solid fa-mobile-signal-out" size="4x" />
                                    </v-col>
                                    <v-col cols="auto" class="mx-2">
                                        <font-awesome-icon icon="fa-solid fa-credit-card-front" size="5x" />
                                    </v-col>
                                    <v-col cols="auto" class="mx-2">
                                        <font-awesome-icon icon="fa-brands fa-cc-visa" size="5x" />
                                    </v-col>
                                    <v-col cols="auto" class="mx-2">
                                        <font-awesome-icon icon="fa-brands fa-cc-mastercard" size="5x" />
                                    </v-col>
                                    <v-col cols="auto" class="mx-2">
                                        <font-awesome-icon icon="fa-brands fa-cc-amex" size="5x" />
                                    </v-col>
                                    <v-col cols="auto" class="mx-2">
                                        <font-awesome-icon icon="fa-brands fa-apple-pay" size="5x" />
                                    </v-col>
                                    <v-col cols="auto" class="mx-2">
                                        <font-awesome-icon icon="fa-brands fa-google-pay" size="5x" />
                                    </v-col>
                                </v-row>
                            </v-window-item>
                            <v-window-item v-for="(seite, index) in cafeteria" :key="index">
                                <v-row justify="space-around">
                                    <v-col cols="12" class="text-center mb-5">
                                        <h1 class="display-2 vox-round-bold">Unser <b>Cafeteria-Angebot</b></h1>
                                    </v-col>
                                    <v-col cols="5" class="my-2" v-for="(artikel, index) in seite.artikel" :key="index">
                                        <v-card dark class="rounded-xl" :color="theme.secondary.get_rgba(0.5)" style="height: 120px;">
                                            <v-row align="center" justify="space-between" class="pa-4">
                                                <v-col cols="9" align-self="center">
                                                    <h2 class="text-truncate">
                                                        <font-awesome-icon v-if="artikel.kategorie" :icon="artikel.kategorie" size="lg" class="mr-3" />
                                                        {{ artikel.name }}
                                                    </h2>
                                                    <small>{{ artikel.beschreibung }}</small>
                                                </v-col>
                                                <v-col cols="3" align-self="center">
                                                    <h1 class="vox-round-bold">{{ parseFloat(artikel.preis).toFixed(2) }}€</h1>
                                                </v-col>
                                            </v-row>
                                        </v-card>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col align-self="center">
                                        <v-progress-linear class="my-2" :value="(spielplan_progress / 300)*100" height="10" rounded color="yellow"></v-progress-linear>
                                    </v-col>
                                    <v-col cols="auto" align-self="center">
                                        <h3 class="white--text"><span class="font-weight-light mr-2">Seite</span>{{ spielplan_seite+1 }}/{{ cafeteria.length }}</h3>
                                    </v-col>
                                </v-row>
                            </v-window-item>
                            
                        </v-window>
                    </v-col>
                    <v-col cols="4">
                        <v-card color="white" class="rounded-xl pa-4 my-4 text-center" v-if="aktuelles_spiel">
                            <v-row justify="space-around" align="center">
                                <v-col cols="12">
                                    <v-row justify="center" align="center" no-gutters>
                                        <h2 class="vox-round-regular mx-3">Aktuelles Spiel</h2>
                                        <v-card class="rounded-xl px-3 text-center mx-3" :color="aktuelles_spiel.spielzeit.status == 'pausiert' ? turnier.website.theme.danger.hex : turnier.website.theme.success.hex" :dark="aktuelles_spiel.spielzeit.status == 'pausiert' ? turnier.website.theme.danger.isDark() : turnier.website.theme.success.isDark()" :light="aktuelles_spiel.spielzeit.status == 'pausiert' ? !turnier.website.theme.danger.isDark() : !turnier.website.theme.success.isDark()">
                                            <h1 class="vox-round-bold">{{ timer.minuten.toString().padStart(2, '0') }}:{{ timer.sekunden.toString().padStart(2, '0') }}</h1>
                                        </v-card>
                                    </v-row>
                                </v-col>
                                <v-col cols="4">
                                    <v-avatar size="60">
                                        <v-img :src="aktuelles_spiel.heim.get_logo()" contain></v-img>
                                    </v-avatar>
                                    <h3>{{ aktuelles_spiel.heim.get_name() }}</h3>
                                </v-col>
                                <v-col cols="4">
                                    <h1 class="display-3 font-weight-bold">{{ aktuelles_spiel.tore_heim }}:{{ aktuelles_spiel.tore_gast }}</h1>
                                </v-col>
                                <v-col cols="4">
                                    <v-avatar size="60">
                                        <v-img :src="aktuelles_spiel.gast.get_logo()" contain></v-img>
                                    </v-avatar>
                                    <h3>{{ aktuelles_spiel.gast.get_name() }}</h3>
                                </v-col>        
                            </v-row>
                        </v-card>
                        <v-card color="white" class="rounded-xl pa-4 my-5 text-center" v-else-if="naechstes_spiel">
                            <v-row justify="space-around" align="center">
                                <v-col cols="12">
                                    <v-row justify="center" align="center" no-gutters>
                                        <h2 class="vox-round-regular mx-3">Nächstes Spiel</h2>
                                    </v-row>
                                </v-col>
                                <v-col cols="4">
                                    <v-avatar size="60">
                                        <v-img :src="naechstes_spiel.heim.get_logo()" contain></v-img>
                                    </v-avatar>
                                    <h3>{{ naechstes_spiel.heim.get_name() }}</h3>
                                </v-col>
                                <v-col cols="4">
                                    <h3 class="vox-round-regular">{{ naechstes_spiel.runde.name }}<br />{{ naechstes_spiel.gruppe.name }}</h3>
                                    <h1 class="display-1 mt-3 font-weight-bold">{{ naechstes_spiel.start.get_uhrzeit_format() }}</h1>
                                </v-col>
                                <v-col cols="4">
                                    <v-avatar size="60">
                                        <v-img :src="naechstes_spiel.gast.get_logo()" contain></v-img>
                                    </v-avatar>
                                    <h3>{{ naechstes_spiel.gast.get_name() }}</h3>
                                </v-col>        
                            </v-row>
                        </v-card>
                        <v-window v-model="tabellen_seite" class="my-5">
                            <v-window-item v-if="naechstes_spiel">
                                <v-card color="grey lighten-1" class="rounded-xl pa-4 mt-4 text-center" >
                                    <v-row justify="space-around" align="center">
                                        <v-col cols="12">
                                            <v-row justify="center" align="center" no-gutters>
                                                <h2 class="vox-round-regular mx-3">Nächstes Spiel</h2>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-avatar size="60">
                                                <v-img :src="naechstes_spiel.heim.get_logo()" contain></v-img>
                                            </v-avatar>
                                            <h3>{{ naechstes_spiel.heim.get_name() }}</h3>
                                        </v-col>
                                        <v-col cols="4">
                                            <h3 class="vox-round-regular">{{ naechstes_spiel.runde.name }}<br />{{ naechstes_spiel.gruppe.name }}</h3>
                                            <h1 class="display-1 mt-3 font-weight-bold">{{ naechstes_spiel.start.get_uhrzeit_format() }}</h1>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-avatar size="60">
                                                <v-img :src="naechstes_spiel.gast.get_logo()" contain></v-img>
                                            </v-avatar>
                                            <h3>{{ naechstes_spiel.gast.get_name() }}</h3>
                                        </v-col>        
                                    </v-row>
                                </v-card>
                            </v-window-item>
                            <v-window-item v-if="beste_torjaeger.length > 0">
                                <v-card :color="turnier.website.theme.secondary.get_rgba(0.5)" :dark="turnier.website.theme.secondary.isDark()" class="rounded-xl">
                                    <v-toolbar :color="turnier.website.theme.secondary.hex">
                                        <h1 class="display-2 text-center">Meiste Tore</h1>
                                        <v-spacer></v-spacer>
                                        <h1 class="vox-round-bold font-weight-bold text-center">{{ turniertore }} <span class="font-weight-light">Turniertore</span></h1>
                                    </v-toolbar>
                                    
                                    <v-list style="background-color: transparent;">
                                        <v-list-item v-for="(ts, index) in beste_torjaeger" :key="index">
                                            <v-list-item-avatar>
                                                <h2>{{ index +1 }}</h2>
                                            </v-list-item-avatar>
                                            <v-list-item-title>{{ ts.spieler.vorname }} {{ ts.spieler.nachname }} <span class="font-weight-light">({{ ts.team.name }})</span></v-list-item-title>
                                            
                                            <v-list-item-action>{{ ts.tore }}</v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                </v-card>
                            </v-window-item>
                            <v-window-item v-for="(gruppe, index) in aktuelle_runde.gruppen" :key="index">
                                <v-card class="rounded-xl pa-2" :color="turnier.website.theme.primary.get_rgba(0.5)" :dark="turnier.website.theme.primary.isDark()">
                                    <v-row class="py-5">
                                        <v-col cols="12">
                                            <h1 class="vox-round-bold text-center yellow--text">Tabelle {{ gruppe.name }}</h1>
                                        </v-col>
                                        <v-col cols="12" class="vox-round-regular pa-2">
                                            <v-row class="mx-2">
                                                <v-col cols="1"></v-col>
                                                <v-col cols="6" class="text-truncate">
                                                    Team
                                                </v-col>
                                                <v-col>Sp</v-col>
                                                <v-col>Tore</v-col>
                                                <v-col>TD</v-col>
                                                <v-col>Pkt</v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12" class="vox-round-regular pa-2" v-for="(team, index) in gruppe.tabelle" :key="team.id">
                                            <v-row class="mx-2" :style="index %2 == 0 ? `background-color: ${turnier.website.theme.secondary.get_rgba(0.6)};` : ''">
                                                <v-col cols="1">{{ index+1 }}</v-col>
                                                <v-col cols="6" class="text-truncate">
                                                    <v-row no-gutters align="center">
                                                        <v-avatar size="30" color="white" class="mr-2">
                                                            <v-img contain :src="team.get_logo()"></v-img>
                                                        </v-avatar>
                                                        <h3>{{ team.get_name() }}</h3>
                                                    </v-row>
                                                </v-col>
                                                <v-col>{{ team.spiele }}</v-col>
                                                <v-col>{{ team.tore }}:{{ team.gegentore }}</v-col>
                                                <v-col>{{ team.tore - team.gegentore }}</v-col>
                                                <v-col>{{ team.punkte }}</v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-window-item>
                        </v-window>
                    </v-col>
                </v-row>
            </v-window-item>
        </v-window>
    </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import QrcodeVue from 'qrcode.vue';

export default {
    name: 'cafeteriadisplay',
    components: {
        QrcodeVue
    },
    data(){
        return {
            timer: {
                minuten: 0,
                sekunden: 0,
            },
            spielplan_progress: 0,
            spielplan_seite: 0,
            tabellen_seite: 1
        }
    },
    computed: {
        ...mapGetters({
            turnier: 'turnier',
        }),
        theme(){
            return this.turnier.website.theme
        },
        aktuelles_spiel(){
            return this.turnier?.spiele?.find(item => item.status == 'gestartet')
        },
        aktuelle_runde(){
            return this.turnier?.runden?.find(item => item.id == this.aktuelles_spiel?.runde.id || item.id == this.naechstes_spiel?.runde.id)
        },
        naechste_spiele(){
            return this.turnier?.spiele?.filter(item => item.status == 'geplant')
        },
        naechstes_spiel(){
            return this.turnier?.spiele?.find(item => item.status == 'geplant')
        },
        spielplan(){
            const spielplan = []
            var i = 0
            this.turnier.spiele.forEach((spiel, index) => {
                if(index % 10 == 0){
                    spielplan.push({ nr: i+1, spiele: [] })
                    i = spielplan.length - 1
                }
                spiel.spielnr = index+1
                spielplan[i].spiele.push(spiel)
            })
            return spielplan
        },
        cafeteria(){
            const cafeteria = []
            var i = 0
            this.turnier.cafeteria.artikel.forEach((artikel, index) => {
                if(index % 10 == 0){
                    cafeteria.push({ nr: i+1, artikel: [] })
                    i = cafeteria.length - 1
                }
                cafeteria[i].artikel.push(artikel)
            })
            return cafeteria
        },
        turniertore(){
            return this.turnier.spiele.map(item => {
                return parseInt(item.tore_heim + item.tore_gast)
            }).reduce((a,b) => a+b, 0)
        },
        beste_torjaeger(){
            let count = 0
            return this.turnier.torjaegerliste.filter(item => {
                if(count < 5 && item.tore > 0){
                    return true
                }
                return false
            })
        }
    },
    mounted() {
        this.start_spielzeit()
        this.spielplan_timer()
    },
    methods: {
        start_spielzeit(){
            const spiel = this.aktuelles_spiel
            
            if(spiel && spiel.status == 'gestartet' && spiel.spielzeit.status == 'gestartet'){
                const spielzeit = Object.assign({}, spiel.spielzeit)
                const now = Date.now()

                const spielzeit_seconds = (spielzeit.verbleibend.minuten * 60) + spielzeit.verbleibend.sekunden 

                const seconds = parseInt((now - spielzeit.verbleibend.timestamp) / 1000)

                this.timer.sekunden = parseInt(spielzeit_seconds - seconds) % 60
                this.timer.minuten = parseInt((spielzeit_seconds - seconds) / 60)

                if(this.timer.sekunden < 0){
                    this.timer.sekunden = 0
                }
                if(this.timer.minuten < 0){
                    this.timer.minuten = 0
                }
            }
            else if(spiel && (spiel.spielzeit.status == 'pausiert' || spiel.status == 'geplant')){
                this.timer.minuten = spiel.spielzeit.verbleibend.minuten
                this.timer.sekunden = spiel.spielzeit.verbleibend.sekunden
            }
            
            setTimeout(() => {
                this.start_spielzeit()
            }, 500)
        },
        spielplan_timer(){
            setTimeout(() => {
                if(this.spielplan_progress == 300){
                    if(this.cafeteria?.length > this.spielplan_seite+1){
                        this.spielplan_seite++
                    }
                    else {
                        this.spielplan_seite = 0
                    }
                    if(this.aktuelle_runde?.gruppen?.length + 2 > this.tabellen_seite+1){
                        this.tabellen_seite++
                    }
                    else {
                        this.tabellen_seite = 0
                    }
                    this.spielplan_progress = -1
                }
                this.spielplan_progress++
                this.spielplan_timer()
            }, 50)
        }
    },
    
}
</script>

<style>
    .v-application {
        color: #fff;
    }

    .v-application .display-4 {
        font-family: "vox-round", sans-serif;
    }

    .v-application .display-3 {
        font-family: "vox-round", sans-serif !important;
    }
    .v-application .display-2 {
        font-family: "vox-round", sans-serif !important;
    }
    .v-application .display-1 {
        font-family: "vox-round", sans-serif !important;
    }

    .score {
        font-size: 26rem !important;
        font-family: "vox-round", sans-serif !important;
        color: #fff;
    }
    .score-goal {
        font-size: 14rem !important;
        font-family: "vox-round", sans-serif !important;
        color: #fff;
    }
    .foulscore {
        font-size: 12rem !important;
        font-family: "vox-round", sans-serif !important;
        font-weight: 700 !important;
        line-height: 12rem;
        color: #fff;
    }
    .spielzeit {
        font-size: 10rem !important;
        line-height: 10rem;
        font-family: "vox-round", sans-serif !important;
    }
</style>