import firebase from '../firebaseConfig'
import { getAuth, updateProfile } from 'firebase/auth'
import { getFirestore, doc, updateDoc } from '@firebase/firestore'

const auth = getAuth()
const db = getFirestore()

const UserModule = {
  state: {
    user: {
      loggedIn: false,
      loader: true,
      loggedOut: false,
      data: null,
    },
  },
  getters: {
    user(state) {
      return state.user
    },
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value
    },
    SET_LOGGED_OUT(state, value) {
      state.user.loggedOut = value
    },
    SET_LOADING(state, value) {
      state.user.loader = value
    },
    SET_USER(state, data) {
      state.user.data = data
      setTimeout(function () {
        state.user.loader = false
      }, 1000)
    },
    SET_USER_PHOTO(state, data) {
      state.user.data.photoUrl = data
      updateProfile(auth.currentUser, {
        photoURL: state.user.data.photoUrl,
      })
    },
    SET_USER_NAME(state, data) {
      state.user.data.displayName = data.vorname + ' ' + data.nachname
      state.user.data.vorname = data.vorname
      state.user.data.nachname = data.nachname
    },
    SET_USER_INVOICE(state, data) {
      state.user.data.invoice = data
    },
    SET_USER_INVOICE_PI(state, data) {
      state.user.data.invoice.payment_intent = data
    },
    SET_USER_MEMBERSHIP(state, data) {
      state.user.data.membership = data
      updateDoc(doc(db, 'User/' + state.user.data.uid), {
        membership: state.user.data.membership,
      })
    },
    SET_USER_STATUS(state, data) {
      state.user.data.status = data
      updateDoc(doc(db, 'User/' + state.user.data.uid), {
        status: state.user.data.status,
      })
    },
    SET_USER_VEREIN(state, data) {
      state.user.data.verein.logo = data.logo
      state.user.data.verein.name = data.name
      state.user.data.verein.kuerzel = data.kuerzel
      state.user.data.verein.farbe1 = data.farbe1
      state.user.data.verein.farbe2 = data.farbe2
      state.user.data.verein.verband = data.verband
    },
    SET_USER_VEREIN_LOGO(state, data) {
      state.user.data.verein.logo = data
      console.log(data)
      updateDoc(doc(db, 'User/' + state.user.data.uid + '/Verein/1'), {
        logo: data,
      })
    },
    SET_SUBSCRIPTION_CANCEL(state, data) {
      state.user.data.subscription.cancel_at_period_end = data
    },
  },
  actions: {
    fetchUser({ commit }, user) {
      commit('SET_LOGGED_IN', user !== null)
      if (user) {
        var photo = 'https://app.iturnier.de/icon?name=user'
        if (user.photoURL) {
          photo = user.photoURL
        }
        if (user.userdata) {
          this.$userid = user.uid
          commit('SET_USER', {
            uid: user.uid,
            displayName: user.displayName,
            vorname: user.userdata.vorname,
            nachname: user.userdata.nachname,
            email: user.email,
            photoUrl: photo,
            stripe: user.userdata.id,
            membership: user.userdata.membership,
            status: user.userdata.status,
            subscription: user.userdata.subscription,
            verein: {
              logo: user.userdata.verein.logo,
              name: user.userdata.verein.name,
              kuerzel: user.userdata.verein.kuerzel,
              farbe1: user.userdata.verein.farbe1,
              farbe2: user.userdata.verein.farbe2,
              verband: user.userdata.verein.verband,
            },
            spotify: user.userdata.spotify
          })
        } else {
          commit('SET_USER', {
            uid: user.uid,
            displayName: user.displayName,
            email: user.email,
            photoUrl: photo,
          })
        }
      } else {
        commit('SET_USER', null)
      }
    },
    updateUser({ commit }, data) {
      var database = false
      var userbase = false
      if (data.type == 'photo') {
        commit('SET_USER_PHOTO', data.photo)
      }
      if (data.type == 'name') {
        commit('SET_USER_NAME', data.name)
      }
      if (data.type == 'verein') {
        commit('SET_USER_VEREIN', data.verein)
      }
      if (data.type == 'vereinslogo') {
        commit('SET_USER_VEREIN_LOGO', data.logo)
      }
      if (data.type == 'membership') {
        commit('SET_USER_MEMBERSHIP', data.membership)
      }
      if (data.type == 'status') {
        commit('SET_USER_STATUS', data.status)
      }
    },
    fetchInvoice({ commit }, data) {
      commit('SET_USER_INVOICE', data)
    },
    fetchInvoicePI({ commit }, data) {
      commit('SET_USER_INVOICE_PI', data)
    },
    showLoggedOut({ commit }) {
      commit('SET_LOGGED_OUT', true)
    },
    changeSubscriptionCancel({ commit }, data) {
      commit('SET_SUBSCRIPTION_CANCEL', data)
    },
    isLoggedIn() {
      return this.state.user.loggedIn
    },
    isLoggedOut() {
      return this.state.user.loggedOut
    },
    showLoader({ commit }) {
      commit('SET_LOADING', true)
    },
    hideLoader({ commit }) {
      commit('SET_LOADING', false)
    },
  },
}

export default UserModule
