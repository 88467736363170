import md5 from "md5";

export class Spieler {
    constructor(
        id,
        vorname,
        nachname,
        trikotnummer,
        geburtsdatum,
        passnummer
    ) {
        this.id = id || md5(Date.now())
        this.vorname = vorname || ''
        this.nachname = nachname || ''
        this.trikotnummer = trikotnummer || ''
        this.erzielte_tore = 0
    }

    get_erzielte_tore(turnier){
        let erzielte_tore = 0

        const spiele = turnier.spiele.filter(item => item.torschuetzen.find(sp => sp.spieler == this.id))
        spiele.forEach(spiel => {
            erzielte_tore += spiel.torschuetzen.filter(item => item.spieler == this.id).length
        })
        
        
        return erzielte_tore
    }

    toFirestore(){
        return {
            id: this.id,
            vorname: this.vorname,
            nachname: this.nachname,
            trikotnummer: this.trikotnummer
        }
    }
}