import Team from "./team";

const teamConverter = {
    toFirestore: (team) => {
        return team.toFirestore()
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        return new Team(
            snapshot.id,
            data.name,
            data.logo,
            data.logo_round,
            data.logo_background,
            data.tormusik,
            snapshot.ref,
            data.kader,
            data.trikots
        )
    }
}

export default teamConverter