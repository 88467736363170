import Vue from 'vue'
import Vuex from 'vuex'
import firebase from './firebaseConfig'
import style from './template'
import UserModule from './store/user'
import TurnierModule from './store/turnier'
import { getFirestore, onSnapshot, query, where } from 'firebase/firestore'
import { collection, doc, setDoc, getDoc, getDocs } from 'firebase/firestore'
import md5 from 'md5'
import rundeConverter from './classes/rundeConverter'
import teamConverter from './classes/spiel/teamConverter'
import turnierConverter from './classes/turnier/turnierConverter'
import { Theme } from './classes/turnier/website/theme'

const db = getFirestore()



Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
  },
  getters: {

  },
  mutations: {
    
  },
  actions: {
    openTurnierseite({ commit }, params) {
      commit('SET_TURNIERSEITE', params)
    },
    setCountdown({ commit }, params) {
      commit('SET_COUNTDOWN', params)
    },
    setTemplate({ commit }, params) {
      commit('SET_TEMPLATE', params)
    },
    fetchMenu({ commit }, menu) {
      commit('SET_MENU', menu.name, menu.data)
    },
    startClock({ commit }) {
      commit('START_CLOCK')
    },
    toggleMobileNav({ commit }, value) {
      commit('TOGGLE_MOBILE_NAV', value)
    },
  },
})

store.registerModule('TurnierModule', TurnierModule)

export default store
