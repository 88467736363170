import Turnier from "./turnier";

const turnierConverter = {
    toFirestore: (turnier) => {
        return turnier.toFirestore()
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        return new Turnier(snapshot.id, data.name, data.alias, data.altersklasse, data.ansprechpartner, data.art, data.turniermodus, data.status, data.anmeldung, data.veranstaltungsorte, data.zeitraum, data.website, data.user, data.ausrichter, snapshot, data.anzeigetafel_settings, data.cafeteria)
    }
}

export default turnierConverter